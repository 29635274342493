<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row" id="article">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search Name"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value=""
                      v-model="filter"
                      v-on:keyup="search"
                    />
                    <div class="input-group-append">
                      <button
                        id="myBtn"
                        class="btn btn-search"
                        @click="searchFor"
                      >
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :items="tableData.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :per-page="0"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
              >
                <template
                  v-can="'update-feedback-status'"
                  v-slot:cell(is_active)="row"
                >
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    @change="updateStatus(row.item.id)"
                    :checked="row.item.is_active == 1 ? true : false"
                  >
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(is_query_solved)="row">
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    @change="updateQueryStatus(row.item.id)"
                    :checked="row.item.is_query_solved == 1 ? true : false"
                  >
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(name)="row">
                  <span v-if="row.item.name" class="">{{ row.item.name }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(email)="row">
                  <span v-if="row.item.email" class="">{{
                    row.item.email
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(message)="row">
                  <span v-if="row.item.message" class="handle-width">{{
                    row.item.message
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(image)="row">
                  <div v-if="row.item.image" class="handle-width">
                    <viewer :images="[row.item.image]">
                      <img :src="row.item.image" height="200" width="200" />
                    </viewer>
                    <span
                      class="badge badge-info mt-2"
                      style="cursor: pointer"
                      @click="show(row.item.image)"
                    >
                      View Attachment
                    </span>
                  </div>
                  <span v-else>---</span>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import feedbackMixin from "../../../mixins/ModuleJs/feedback";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);

export default {
  mixins: [MixinRequest, feedbackMixin],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Feedback",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  methods: {
    show(img) {
      this.$viewerApi({
        images: [img],
      });
    },
  },
};
</script>
